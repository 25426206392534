import { Spinner } from '@capturi/ui-components'
import { useQueryClient } from '@tanstack/react-query'
import React from 'react'
import { useKeyTopicsDiscoveryResult } from './useKeyTopicsDiscovery'
import { Channel } from './useKeyTopicsDiscovery'

type Props = {
  discoveryUid: string
  organizationUid: string | undefined
  channel: Channel
}

const KeyTopicsDiscoveryLoading: React.FC<Props> = ({
  discoveryUid,
  organizationUid,
  channel,
}) => {
  const { data } = useKeyTopicsDiscoveryResult(channel, discoveryUid)
  const queryClient = useQueryClient()

  if (data?.status === 'Success') {
    queryClient.invalidateQueries({
      queryKey: [
        'superpowers',
        'keyTopicsDiscoveries',
        channel,
        'organization',
        organizationUid,
      ],
    })
  }
  return <Spinner />
}

export default KeyTopicsDiscoveryLoading
