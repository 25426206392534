import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

export type KeyTopicDiscovery = {
  discoveryUid: string
  createdDate: Date
  isProd: boolean
  status: 'Success' | 'Processing' | 'Error' | 'NotStarted'
  organizationDescription: string
  mainCategories: MainCategory[]
  irrelevantDimensions: string[]
}

type MainCategory = {
  displayName: string
  description: string
}

export type Channel = 'speech' | 'text'

type DiscoveryStartRequest = {
  organizationUid: string | undefined
  fromDate: Date
  toDate: Date
  channel: Channel
}
type DiscoveryStartResponse = { discoveryUid: string }

function getBaseUrl(channel: Channel) {
  // Decide endpoint based on channel
  const base =
    channel === 'speech'
      ? 'superpowers/asr/key-topics'
      : 'superpowers/asr/key-topics/cases'
  return base
}

export const useKeyTopicsDiscoveryStart = (): UseMutationResult<
  string,
  ResponseError,
  DiscoveryStartRequest,
  ResponseError
> => {
  return useMutation({
    mutationFn: async ({ organizationUid, fromDate, toDate, channel }) => {
      const result = await request.post<DiscoveryStartResponse>(
        `${getBaseUrl(channel)}/${organizationUid}`,
        { json: { fromDate, toDate } },
      )
      return result.discoveryUid
    },
  })
}

export const useKeyTopicsDiscoveries = (
  channel: Channel,
  organizationUid: string | undefined,
): UseQueryResult<KeyTopicDiscovery[], ResponseError> =>
  useQuery({
    queryKey: [
      'superpowers',
      'keyTopicsDiscoveries',
      channel,
      'organization',
      organizationUid,
    ],
    queryFn: async () => {
      const result = await request.get<KeyTopicDiscovery[]>(
        `${getBaseUrl(channel)}/${organizationUid}`,
      )
      return result
    },
  })

export const useKeyTopicsDiscoveryUpdate = (
  channel: Channel,
): UseMutationResult<
  KeyTopicDiscovery,
  ResponseError,
  Partial<KeyTopicDiscovery>,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (model: Partial<KeyTopicDiscovery>) => {
      return request.patch<KeyTopicDiscovery>(
        `${getBaseUrl(channel)}/${model.discoveryUid}/update`,
        {
          json: model,
        },
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['superpowers', 'keyTopicsDiscoveries'],
      })
    },
  })
}

export const useDeleteKeyTopicDiscovery = (
  channel: Channel,
  organizationUid: string,
): UseMutationResult<
  KeyTopicDiscovery,
  ResponseError,
  string,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (discoveryUid: string) => {
      return request.delete<KeyTopicDiscovery>(
        `${getBaseUrl(channel)}/${discoveryUid}/delete`,
      )
    },
    onSuccess: (_, discoveryUid) => {
      queryClient.setQueryData<KeyTopicDiscovery[]>(
        [
          'superpowers',
          'keyTopicsDiscoveries',
          channel,
          'organization',
          organizationUid,
        ],
        (oldData) => {
          if (!oldData) {
            return []
          }
          return oldData.filter((ktd) => ktd.discoveryUid !== discoveryUid)
        },
      )
    },
  })
}

export const useKeyTopicsDiscoveryResult = (
  channel: Channel,
  discoveryUid: string,
): UseQueryResult<KeyTopicDiscovery, ResponseError> =>
  useQuery({
    queryKey: [
      'superpowers',
      'keyTopicsDiscoveries',
      channel,
      'discovery',
      discoveryUid,
    ],
    queryFn: async () => {
      const result = await request.get<KeyTopicDiscovery>(
        `${getBaseUrl(channel)}/${discoveryUid}/result`,
      )
      return result
    },
    refetchInterval: ({ state }) =>
      state.data?.status === 'Success' ? false : 3000,
  })
