import { useInput } from '@capturi/react-utils'
import request, { ResponseError } from '@capturi/request'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@capturi/ui-components'
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Switch,
  Text,
} from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { noop } from '../utils'
import {
  useCreateOrganization,
  useUpdateOrganization,
} from './useOrganizations'

type Props = {
  uid?: string
  name?: string
  conversationLanguage?: string
  organizationType?: string
  uiLanguage?: string
  limitedAccess?: boolean
  onClose: () => void
  provisionId?: string
}

const CreateUpdateOrganization: React.FC<Props> = ({
  name,
  uiLanguage,
  conversationLanguage,
  organizationType,
  uid,
  limitedAccess: initialLimitedAccess = false,
  provisionId,
  onClose,
}) => {
  const { data: languages, error: fetchLanguagesError } = useQuery<
    string[],
    ResponseError
  >({
    queryKey: ['superpowers', 'organisation', 'languages'],
    queryFn: () =>
      request.get('superpowers/organization/conversation-languages'),
  })
  const navigate = useNavigate()

  const create = useCreateOrganization()
  const update = useUpdateOrganization()

  const [state, setState] = useInput({
    name: name || 'Demo',
    uiLanguage: uiLanguage || 'da-DK',
    conversationLanguage: conversationLanguage || 'da-DK',
    organizationType: organizationType || 'private',
  })
  const [limitedAccess, setLimitedAccess] = useState(initialLimitedAccess)
  const inProgress = create.isPending || update.isPending
  const error = create.error || update.error
  const isNew = !uid || provisionId

  const handleSave = async (): Promise<void> => {
    const {
      conversationLanguage,

      name,
      organizationType,
      uiLanguage,
    } = state

    if (!isNew) {
      update.mutate(
        {
          uid,
          payload: {
            limitedAccess,
            name,
            organizationType,
            uiLanguage,
          },
        },
        { onSuccess: onClose },
      )
    } else {
      create.mutate(
        {
          uid: uid,
          name,
          conversationLanguage,
          limitedAccess,
          organizationType,
          uiLanguage,
        },
        {
          onSuccess: (result) => {
            if (provisionId) {
              //mark as solved
            }

            navigate(
              `/superpowers/organization/${result.uid}/config?isNew=true`,
            )
            onClose()
          },
          onError: (err) => {
            if (err.statusCode === 409) {
              //mark as done
            }
          },
        },
      )
    }
  }

  return (
    <Modal isOpen onClose={inProgress ? noop : onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {isNew ? 'Create organization' : 'Update organization'}
        </ModalHeader>
        {!inProgress && <ModalCloseButton />}
        <ModalBody>
          <FormControl>
            <FormLabel htmlFor="name">Organization name</FormLabel>
            <Input
              autoComplete="off"
              isDisabled={inProgress}
              type="text"
              id="name"
              aria-describedby="organization name"
              {...setState.name}
            />
            <FormLabel mt="3" htmlFor="conversation-language">
              Conversation language
            </FormLabel>
            <Select
              disabled={!isNew || inProgress || !languages}
              id="conversation-language"
              {...setState.conversationLanguage}
            >
              {languages?.map((l) => (
                <option value={l} key={l}>
                  {l}
                </option>
              ))}
            </Select>
            <FormLabel mt="3" htmlFor="ui-language">
              UI language
            </FormLabel>
            <Select
              disabled={inProgress}
              id="ui-language"
              {...setState.uiLanguage}
            >
              <option value="da-DK">da-DK</option>
              <option value="en-US">en-US</option>
            </Select>
            <FormLabel mt="3" htmlFor="ui-language">
              Organization type
            </FormLabel>
            <Select
              disabled={inProgress}
              id="organization-type"
              {...setState.organizationType}
            >
              <option value="private">Private</option>
              <option value="public">Public</option>
            </Select>

            <Flex mt="4" direction="row" alignItems="center">
              <Switch
                key="limitedAccess"
                colorScheme="orange"
                isChecked={limitedAccess}
                onChange={(e) => {
                  setLimitedAccess(e.currentTarget.checked)
                }}
                mr={2}
                isDisabled={inProgress}
              />
              <FormLabel htmlFor="limitedAccess" fontWeight="normal" m={0}>
                Limited Access
              </FormLabel>
            </Flex>
          </FormControl>
          {!!error && (
            <Text color="red.500" textAlign="center" mt="4">
              {error.message}
            </Text>
          )}
          {!!fetchLanguagesError && (
            <Text color="red.500" textAlign="center" mt="4">
              {`Could not fetch conversations languages - ${fetchLanguagesError.message}`}
            </Text>
          )}
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" isDisabled={inProgress} onClick={onClose}>
            Cancel
          </Button>
          <Button
            isLoading={inProgress}
            colorScheme="primary"
            mr={3}
            onClick={handleSave}
          >
            {isNew ? 'Create' : 'Update'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CreateUpdateOrganization
