import {
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useParams } from 'react-router'

import { useFeatureFlags } from '../Organization/tabs/Config/FeatureFlags/api/useFeatureFlags'
import { KeyTopicsPanel } from './KeyTopicsPanel'
import { Channel } from './useKeyTopicsDiscovery'

const KeyTopicsWrapper: React.FC = () => {
  const { uid: organizationUid } = useParams()

  if (organizationUid == null) return <div>Not found</div>

  return <KeyTopics organizationUid={organizationUid} />
}

type KeyTopicsProps = {
  organizationUid: string
}

const KeyTopics: React.FC<KeyTopicsProps> = ({ organizationUid }) => {
  const { data: featureFlags } = useFeatureFlags(organizationUid)
  const textEnabled = featureFlags?.enableText === true
  const textOnlyOrg =
    textEnabled && featureFlags?.useEmailChannelAsDefault === true
  const [activeChannel, setActiveChannel] = useState<Channel>(
    textOnlyOrg ? 'text' : 'speech',
  )

  return (
    <>
      <Heading mb={3} fontSize="18px" fontWeight="medium">
        Key Topics
      </Heading>

      <Tabs
        isLazy
        lazyBehavior="unmount"
        index={activeChannel === 'speech' ? 0 : 1}
        onChange={(index) => setActiveChannel(index === 0 ? 'speech' : 'text')}
      >
        <TabList>
          <Tab>Speech</Tab>
          {textEnabled && <Tab>Text</Tab>}
        </TabList>
        <TabPanels>
          <TabPanel>
            <KeyTopicsPanel
              channel="speech"
              organizationUid={organizationUid}
            />
          </TabPanel>
          {textEnabled && (
            <TabPanel>
              <KeyTopicsPanel
                channel="text"
                organizationUid={organizationUid}
              />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </>
  )
}

export default KeyTopicsWrapper
